import {createReducer, on} from '@ngrx/store';
import * as CheckoutActions from './checkout.actions';
import {CheckoutState, desktopArch, mobileArch, monoRepoArch} from './checkout.model';
import {
  updateAllFormDataStatus,
  updateAppsDirectoryStructure,
  updateLibsDirectoryStructure
} from './checkout.fns';

export const checkoutsFeatureKey = 'checkout';

export const initialCheckoutState: CheckoutState = {
  isLoaded: false,
  isLoading: true,
  error: null,

  checkoutFormStatus: 'INVALID',
  monoRepoFormStatus: 'INVALID',
  mobileFormStatus: 'INVALID',
  desktopFormStatus: 'INVALID',

  checkoutFormData: {
    contactEmail: '',
    monoRepoArch: true,
    mobileArch: false,
    desktopArch: false
  },

  monoRepoFormData: {
    repositoryName: 'fly-anywhere-airline',
    domainName: 'flights',
    initialAppName: 'booking'
    // sameDomainForMobileAndDesktopApps: true
  },

  mobileFormData: {
    domainName: 'flights',
    mobileAppName: 'capacitorjs-booking'
  },

  desktopFormData: {
    domainName: 'flights',
    desktopAppName: 'electronjs-booking'
  },

  monoRepoArchProduct: {...monoRepoArch},
  desktopArchProduct: {...desktopArch},
  mobileArchProduct: {...mobileArch},

  apps: {
    firebaseDirectoryName: 'firebase',

    appsDomainDirectoryName: 'flights',
    appDirectoryName: 'booking',
    appE2EDirectoryName: 'booking-e2e',

    mobileDirectoryName: 'capacitorjs-booking',
    desktopDirectoryName: 'electronjs-booking',

    storyBookDirectoryName: 'storybook',
    storyBookAppDirectoryName: 'storybook-app',
    storyBookE2EDirectoryName: 'storybook-e2e'
  },
  libs: {
    libsDomainDirectoryName: 'flights',
    apiDirectoryName: 'api-booking',
    domainDirectoryName: 'domain',
    featureDirectoryName: 'feature-booking',
    uiDirectoryName: 'ui-booking',
    utilDirectoryName: 'util-booking'
  }
};

export const checkoutReducer = createReducer(
  initialCheckoutState,
  on(CheckoutActions.clearState, () => {
    return {
      ...initialCheckoutState,
      isLoaded: true,
      checkoutFormData: {
        ...initialCheckoutState.checkoutFormData
      },
      monoRepoFormData: {
        ...initialCheckoutState.monoRepoFormData
      },
      mobileFormData: {
        ...initialCheckoutState.mobileFormData
      },
      desktopFormData: {
        ...initialCheckoutState.desktopFormData
      },
      monoRepoArchProduct: {...monoRepoArch},
      desktopArchProduct: {...desktopArch},
      mobileArchProduct: {...mobileArch}
    };
  }),
  on(CheckoutActions.checkoutError, (state, {message}) => ({
    ...state,
    error: message
  })),
  on(CheckoutActions.updateCheckoutState, (state, {update}) =>
    updateAllFormDataStatus({
      ...state,
      ...update,
      isLoaded: true,
      isLoading: false
    })
  ),
  on(
    CheckoutActions.updateCheckoutFromData,
    (state: CheckoutState, {checkoutFormData}): CheckoutState => {
      return updateAllFormDataStatus({
        ...state,
        isLoaded: true,
        isLoading: false,
        checkoutFormData: {
          ...checkoutFormData
        }
      });
    }
  ),
  on(
    CheckoutActions.updateDesktopFormData,
    (state: CheckoutState, {desktopFormData}): CheckoutState => {
      return updateAllFormDataStatus({
        ...state,
        isLoaded: true,
        isLoading: false,
        desktopFormData: {
          ...state.desktopFormData,
          ...desktopFormData
        }
      });
    }
  ),
  on(
    CheckoutActions.updateMobileFormData,
    (state: CheckoutState, {mobileFormData}): CheckoutState => {
      return updateAllFormDataStatus({
        ...state,
        isLoaded: true,
        isLoading: false,
        mobileFormData: {
          ...state.mobileFormData,
          ...mobileFormData
        }
      });
    }
  ),
  on(
    CheckoutActions.updateMonoRepoFormData,
    (state: CheckoutState, {monoRepoFormData}): CheckoutState => {
      const _state = updateAllFormDataStatus({
        ...state,
        monoRepoFormData,
        isLoaded: true,
        isLoading: false,
        mobileFormData: {
          ...state.mobileFormData,
          domainName: monoRepoFormData.domainName,
          mobileAppName: `mobile-${monoRepoFormData.initialAppName}`
        },
        desktopFormData: {
          ...state.desktopFormData,
          domainName: monoRepoFormData.domainName,
          desktopAppName: `desktop-${monoRepoFormData.initialAppName}`
        }
        // mobileFormData: {
        //   ...state.mobileFormData,
        //   domainName: monoRepoFormData.sameDomainForMobileAndDesktopApps
        //     ? monoRepoFormData.domainName
        //     : state.mobileFormData.domainName,
        //   mobileAppName: monoRepoFormData.sameDomainForMobileAndDesktopApps
        //     ? `mobile-${monoRepoFormData.initialAppName}`
        //     : state.mobileFormData.mobileAppName
        // },
        // desktopFormData: {
        //   ...state.desktopFormData,
        //   domainName: monoRepoFormData.sameDomainForMobileAndDesktopApps
        //     ? monoRepoFormData.domainName
        //     : state.desktopFormData.domainName,
        //   desktopAppName: monoRepoFormData.sameDomainForMobileAndDesktopApps
        //     ? `desktop-${monoRepoFormData.initialAppName}`
        //     : state.desktopFormData.desktopAppName
        // }
      });

      _state.apps = updateAppsDirectoryStructure(_state);
      _state.libs = updateLibsDirectoryStructure(_state);

      return _state;
    }
  )
  // on(
  //   CheckoutActions.checkoutFormStatus,
  //   (state: CheckoutState, { status }): CheckoutState => {
  //     return updateAllFormDataStatus({
  //       ...state,
  //       checkoutFormStatus: status
  //     });
  //   }
  // ),
  // on(
  //   CheckoutActions.monoRepoFormStatus,
  //   (state: CheckoutState, { status }): CheckoutState => {
  //     return updateAllFormDataStatus({
  //       ...state,
  //       monoRepoFormStatus: status
  //     });
  //   }
  // ),
  // on(
  //   CheckoutActions.mobileFormStatus,
  //   (state: CheckoutState, { status }): CheckoutState => {
  //     return updateAllFormDataStatus({
  //       ...state,
  //       mobileFormStatus: status
  //     });
  //   }
  // ),
  // on(
  //   CheckoutActions.desktopFormStatus,
  //   (state: CheckoutState, { status }): CheckoutState => {
  //     return updateAllFormDataStatus({
  //       ...state,
  //       desktopFormStatus: status
  //     });
  //   }
  // )
);
