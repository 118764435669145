import {
  AllFormData,
  AllFormDataLoaded,
  AllFormDataStatus,
  AllFormDataStatusLoaded,
  AllFormDataWithPrice,
  ArchDomainApps,
  ArchDomainLibs,
  CheckoutFormData,
  CheckoutState,
  DesktopFormData,
  GigaPriceMetaData,
  MobileFormData,
  MonoRepoFormData,
  stripeProducts
} from './checkout.model';
import { FormControlStatus } from '@angular/forms';
import { hasValue } from '@ngpat/fn';
import {
  NgPatStripeCheckoutSessionItem,
  NgPatStripeProductPrice
} from '@ngpat/store';

export function getAllFormData(data: AllFormDataLoaded): AllFormData {
  return {
    checkoutFormData: data.checkoutFormData,
    monoRepoFormData: data.monoRepoFormData,
    mobileFormData: data.mobileFormData,
    desktopFormData: data.desktopFormData
  };
}

export function getAllFormDataStatusLoaded(
  s: CheckoutState
): AllFormDataStatusLoaded {
  return {
    isLoaded: s.isLoaded,
    checkoutFormStatus: s.checkoutFormStatus,
    monoRepoFormStatus: s.monoRepoFormStatus,
    mobileFormStatus: s.mobileFormStatus,
    desktopFormStatus: s.desktopFormStatus,
    checkoutFormData: s.checkoutFormData,
    monoRepoFormData: s.monoRepoFormData,
    mobileFormData: s.mobileFormData,
    desktopFormData: s.desktopFormData,
    apps: s.apps,
    libs: s.libs
  };
}

function checkoutFormStatus(f: CheckoutFormData): FormControlStatus {
  return f.contactEmail && f.contactEmail.length > 0 ? 'VALID' : 'INVALID';
}

function monoRepoFormStatus(f: MonoRepoFormData): FormControlStatus {
  return hasValue(f.repositoryName) &&
    hasValue(f.domainName) &&
    hasValue(f.initialAppName)
    ? 'VALID'
    : 'INVALID';
}

function mobileFormStatus(f: MobileFormData): FormControlStatus {
  return hasValue(f.mobileAppName) && hasValue(f.domainName)
    ? 'VALID'
    : 'INVALID';
}

function desktopFormStatus(f: DesktopFormData): FormControlStatus {
  return hasValue(f.desktopAppName) && hasValue(f.domainName)
    ? 'VALID'
    : 'INVALID';
}

export function updateAllFormDataStatus(s: CheckoutState): CheckoutState {
  return {
    ...s,
    checkoutFormStatus: checkoutFormStatus(s.checkoutFormData),
    monoRepoFormStatus: monoRepoFormStatus(s.monoRepoFormData),
    mobileFormStatus: mobileFormStatus(s.mobileFormData),
    desktopFormStatus: desktopFormStatus(s.desktopFormData),
    checkoutFormData: {
      ...s.checkoutFormData
    },
    monoRepoFormData: {
      ...s.monoRepoFormData
    },
    mobileFormData: {
      ...s.mobileFormData
    },
    desktopFormData: {
      ...s.desktopFormData
    },
    apps: {
      ...s.apps
    },
    libs: {
      ...s.libs
    }
  };
}

export function getAllFormDataAndStatus(
  s: AllFormDataStatus
): AllFormDataStatus {
  return {
    checkoutFormStatus: checkoutFormStatus(s.checkoutFormData),
    monoRepoFormStatus: monoRepoFormStatus(s.monoRepoFormData),
    mobileFormStatus: mobileFormStatus(s.mobileFormData),
    desktopFormStatus: desktopFormStatus(s.desktopFormData),
    checkoutFormData: {
      ...s.checkoutFormData
    },
    monoRepoFormData: {
      ...s.monoRepoFormData
    },
    mobileFormData: {
      ...s.mobileFormData
    },
    desktopFormData: {
      ...s.desktopFormData
    },
    apps: {
      ...s.apps
    },
    libs: {
      ...s.libs
    }
  };
}

function createStripePricePayload(
  price: NgPatStripeProductPrice
): NgPatStripeCheckoutSessionItem {
  return {
    dynamic_tax_rates: [],
    price: price.id,
    quantity: 1
  };
}

export function mapStripePriceWithFormData(
  f: AllFormDataWithPrice
): NgPatStripeCheckoutSessionItem[] {
  const pricePayload: NgPatStripeCheckoutSessionItem[] = [];

  for (let i = 0; i < f.prices.length; i++) {
    const price: NgPatStripeProductPrice = f.prices[i];
    const metadata: GigaPriceMetaData = (<unknown>(
      price.metadata
    )) as GigaPriceMetaData;

    if (
      metadata.firebaseProduct === stripeProducts.MONOREPO &&
      f.formData.checkoutFormData.monoRepoArch
    ) {
      pricePayload.push(createStripePricePayload(price));
    }

    if (
      metadata.firebaseProduct === stripeProducts.MOBILE &&
      f.formData.checkoutFormData.mobileArch
    ) {
      pricePayload.push(createStripePricePayload(price));
    }

    if (
      metadata.firebaseProduct === stripeProducts.DESKTOP &&
      f.formData.checkoutFormData.desktopArch
    ) {
      pricePayload.push(createStripePricePayload(price));
    }
  }

  return pricePayload;
}

export function updateAppsDirectoryStructure(
  state: CheckoutState
): ArchDomainApps {
  return {
    firebaseDirectoryName: 'firebase',

    appsDomainDirectoryName: state.monoRepoFormData.domainName,

    appDirectoryName: state.monoRepoFormData.initialAppName,
    appE2EDirectoryName: `${state.monoRepoFormData.initialAppName}-e2e`,

    mobileDirectoryName: `mobile-${state.monoRepoFormData.initialAppName}`,
    desktopDirectoryName: `desktop-${state.monoRepoFormData.initialAppName}`,

    storyBookDirectoryName: 'storybook',
    storyBookAppDirectoryName: 'storybook-app',
    storyBookE2EDirectoryName: 'storybook-e2e'
  };
}

export function updateLibsDirectoryStructure(
  state: CheckoutState
): ArchDomainLibs {
  return {
    libsDomainDirectoryName: state.monoRepoFormData.domainName,
    apiDirectoryName: `api-${state.monoRepoFormData.initialAppName}`,
    domainDirectoryName: 'domain',
    featureDirectoryName: `feature-${state.monoRepoFormData.initialAppName}`,
    uiDirectoryName: `ui-${state.monoRepoFormData.initialAppName}`,
    utilDirectoryName: `util-${state.monoRepoFormData.initialAppName}`
  };
}

export function getRouteWithoutParams(href: string): string {
  return href.substring(0, href.indexOf('?'));
}
