import 'cordova-plugin-purchase';
import {Capacitor} from '@capacitor/core';
import {MaxCountIterator} from '@ngpat/rxjs';
import {BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {
  DEVICE_TYPE,
  IAPError,
  InAppPurchaseConfig,
  IRefeshResult
} from './in-app-purchase.wrapper.model';
import IRegisterProduct = CdvPurchase.IRegisterProduct;
import LogLevel = CdvPurchase.LogLevel;
import Platform = CdvPurchase.Platform;
import Product = CdvPurchase.Product;
import When = CdvPurchase.When;
import IapticConfig = CdvPurchase.IapticConfig;

/**
 * GitHub issue:
 * https://github.com/danielsogl/awesome-cordova-plugins/issues/4457
 *
 * API for in-app purchases.
 * https://github.com/j3k0/cordova-plugin-purchase/blob/v11/doc/api.md#life-cycle
 *
 * he cordova-plugin is not working, this is a customer implimentation of the plugin:
 * https://github.com/danielsogl/awesome-cordova-plugins/issues/4457
 *
 * See Migration guide
 * https://github.com/j3k0/cordova-plugin-purchase/wiki/HOWTO:-Migrate-to-v13
 *
 * See Example usage in:
 * https://github.com/IsraelHikingMap/Site/blob/main/IsraelHiking.Web/src/application/services/purchase.service.ts
 * Cloned to /Dev/wrapper-research/Site/IsraelHiking.Web/src/application/services/purchase.service.ts
 *
 * More Examples:
 * https://github.com/danielsogl/awesome-cordova-plugins/issues/4457
 *
 * Original Plugin
 * https://github.com/danielsogl/awesome-cordova-plugins/blob/master/src/%40awesome-cordova-plugins/plugins/in-app-purchase-2/index.ts
 */
export class InAppPurchaseWrapper {
  static LogLevel = CdvPurchase.LogLevel;
  static ProductType = CdvPurchase.ProductType;
  static Platform = CdvPurchase.Platform;

  deviceReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _products$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  products$ = this._products$.asObservable();

  get deviceReady(): boolean {
    return this.deviceReady$.getValue();
  }

  /**
   * Purchase platforms supported by the plugin
   */
  static get currentPlatform(): Platform {
    // Gets the name of the platform, such as android, ios, or web.
    const platform = Capacitor.getPlatform();

    if (platform === 'ios') {
      return Platform.APPLE_APPSTORE;
    } else {
      return Platform.GOOGLE_PLAY;
    }
  }

  static get isNativePlatform(): boolean {
    return Capacitor.isNativePlatform();
  }

  static get isAppleAppStore(): boolean {
    return InAppPurchaseWrapper.currentPlatform === Platform.APPLE_APPSTORE;
  }

  static get isGooglePlay(): boolean {
    return InAppPurchaseWrapper.currentPlatform === Platform.GOOGLE_PLAY;
  }

  /**
   * return url like 'https://validator.iaptic.com/v3/validate?appName=com.evolvingcognition.app&apiKey=17546b79-d7c4-4ded-852e-d4e234327318'
   */

  get CdvPurchase(): typeof CdvPurchase {
    return (<Window & {CdvPurchase: typeof CdvPurchase}>this.config.win).CdvPurchase;
  }

  private deviceReadyCounter = new MaxCountIterator(250, 10000);

  /**
   * https://github.com/j3k0/cordova-plugin-purchase/blob/master/api/classes/CdvPurchase.Iaptic.md
   */
  // iaptic: CdvPurchase.Iaptic;

  constructor(
    private config: InAppPurchaseConfig,
    private onDeviceReady: ((deviceType: DEVICE_TYPE) => void) | null = null
  ) {
    const iapticConfig: IapticConfig = {
      apiKey: config.apiKey,
      appName: config.appName
    };

    if (config.baseUrl) {
      iapticConfig.url = config.baseUrl;
    }

    // console.log('[IAP] IAPTIC CONFIG', iapticConfig);

    // this.iaptic = new CdvPurchase.Iaptic(iapticConfig);

    // this.checkNativeDeviceReady.call(this);

    if (Capacitor.isNativePlatform()) {
      this.checkNativeDeviceReady.call(this);
    } else {
      if (this.onDeviceReady) {
        this.onDeviceReady(DEVICE_TYPE.WEB);
      }
    }
  }

  private checkNativeDeviceReady() {
    console.log('[IAP] CHECK DEVICE READY');
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    this.deviceReadyCounter.currentCount$.subscribe((count: number) => {
      console.log('[IAP] DEVICE READY MAX COUNT', count);

      if (that.CdvPurchase || this.deviceReadyCounter.isMaxCountReached) {
        that.deviceReadyCounter.stop();
        // that.setup();
        if (that.onDeviceReady) {
          that.onDeviceReady(DEVICE_TYPE.NATIVE);
        }
        that.deviceReady$.next(true);
      }
    });

    this.deviceReadyCounter.start();
  }

  set applicationUsername(uid: string) {
    this.CdvPurchase.store.applicationUsername = uid;
  }

  set verbosity(level: LogLevel) {
    this.CdvPurchase.store.verbosity = level;
  }

  private _error$: BehaviorSubject<string | unknown | null> = new BehaviorSubject<
    string | unknown | null
  >(null);
  private _warn$: BehaviorSubject<string | unknown | null> = new BehaviorSubject<
    string | unknown | null
  >(null);
  private _log$: BehaviorSubject<string | unknown | null> = new BehaviorSubject<
    string | unknown | null
  >(null);

  error$ = this._error$
    .asObservable()
    .pipe(filter((message: string | unknown | null) => !!message));
  warn$ = this._warn$.asObservable().pipe(filter((message: string | unknown | null) => !!message));
  log$ = this._log$.asObservable().pipe(filter((message: string | unknown | null) => !!message));

  setup() {
    // this.CdvPurchase.Logger.console = {
    //   error: (message: string | unknown) => {
    //     this._error$.next(`[IAP] ${message}`);
    //   },
    //   warn: (message: string | unknown) => {
    //     this._warn$.next(`[IAP] ${message}`);
    //   },
    //   log: (message: string | unknown) => {
    //     this._log$.next(`[IAP] ${message}`);
    //   }
    // };
    /**
     * https://github.com/j3k0/cordova-plugin-purchase/blob/master/api/classes/CdvPurchase.Iaptic.md
     */
    // this.CdvPurchase.store.validator =
    //   'https://validator.iaptic.com/v3/validate?appName=com.evolvingcognition.app&apiKey=17546b79-d7c4-4ded-852e-d4e234327318';
    // this.CdvPurchase.store.validator = this.iaptic.validator;
    // console.log('[IAP] VALIDATOR', this.CdvPurchase.store.validator);
  }

  /**
   * return url like `https://validator.iaptic.com/v3/customers/${uid}/purchases?appName=com.evolvingcognition.app&apiKey=17546b79-d7c4-4ded-852e-d4e234327318`
   * @param config
   * @param onDeviceReady
   */
  validatePurchasesUrl(uid: string): string {
    return `${this.config.baseUrl}/customers/${uid}/purchases?appName=${this.config.appName}&apiKey=${this.config.apiKey}`;
  }

  getApplicationUsername(): string | undefined {
    return;
  }

  /**
   * Get product by id or alias
   *
   * @param idOrAlias
   */
  get(idOrAlias: string): Product | undefined {
    return this.CdvPurchase.store.get(idOrAlias);
  }

  /**
   * Register error handler
   *
   * @param onError {Function} function to call on error
   */
  error(onError: (err: CdvPurchase.IError) => void): void {
    this.CdvPurchase.store.error(onError);
  }

  /**
   * Add or register a product
   *
   * @param product {IAPProductOptions}
   */
  register(products: IRegisterProduct | IRegisterProduct[]): void {
    this.CdvPurchase.store.register(products);
  }

  /**
   *
   * @param query
   * @param event
   * @param callback
   * @returns {IAPProductEvents}
   */
  when(): When {
    return this.CdvPurchase.store.when();
  }

  get products(): Product[] {
    return this.CdvPurchase.store.products;
  }

  /**
   * Unregister a callback. Works for callbacks registered with ready, when, once and error.
   *
   * @param callback {Function}
   */
  off(callback: (product: Product | any) => void): void {
    // noop;
  }

  order(product: string | Product, additionalData?: any): Promise<any> | undefined {
    return;
  }

  ready(callback: () => void): void {
    // noop
  }

  refresh(): IRefeshResult | undefined {
    return;
  }

  manageSubscriptions() {
    // noop
  }
}
