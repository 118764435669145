import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AllFormData,
  AllFormDataLoaded,
  AllFormDataWithPrice,
  CheckoutFormData,
  CheckoutState,
  checkoutSubTotal,
  DesktopFormData,
  MobileFormData,
  MonoRepoFormData,
  SameDomain
} from './checkout.model';
import * as CheckoutReducer from './checkout.reducer';
import {
  getAllFormDataAndStatus,
  getAllFormDataStatusLoaded
} from './checkout.fns';
import {
  NgPatStripeProductPrice,
  selectNgPatAllStripePrices,
  selectNgPatLoggedInUID
} from '@ngpat/store';

export const selectCheckoutState = createFeatureSelector<CheckoutState>(
  CheckoutReducer.checkoutsFeatureKey
);

export const selectCheckoutLoadingInProgress = createSelector(
  selectCheckoutState,
  (state: CheckoutState) => state.isLoading
);

export const selectCheckoutLoaded = createSelector(
  selectCheckoutState,
  (state: CheckoutState) => state.isLoaded
);

export const selectCheckoutError = createSelector(
  selectCheckoutState,
  (state: CheckoutState) => state.error
);

export const selectMonoRepoArch = createSelector(
  selectCheckoutState,
  (state: CheckoutState) => state.monoRepoArchProduct
);

export const selectCheckoutFormData = createSelector(
  selectCheckoutState,
  (state: CheckoutState): CheckoutFormData => state.checkoutFormData
);

export const selectMonoRepoFormData = createSelector(
  selectCheckoutState,
  (state: CheckoutState) => state.monoRepoFormData
);

export const selectMobileFormData = createSelector(
  selectCheckoutState,
  (state: CheckoutState): MobileFormData => state.mobileFormData
);

export const selectSameDomainNameForMobileAndDesktopApps = createSelector(
  selectMonoRepoFormData,
  (f: MonoRepoFormData): SameDomain => {
    return <SameDomain>{
      // sameDomainForMobileAndDesktopApps: f.sameDomainForMobileAndDesktopApps,
      domainName: f.domainName
    };
  }
);

export const selectDesktopFormData = createSelector(
  selectCheckoutState,
  selectSameDomainNameForMobileAndDesktopApps,
  (state: CheckoutState, s: SameDomain): DesktopFormData => {
    return {
      ...state.desktopFormData,
      domainName: s.domainName
      // domainName: s.sameDomainForMobileAndDesktopApps
      //   ? s.domainName
      //   : state.desktopFormData.domainName
    };
  }
);

export const selectCheckoutWithMonoRepoArch = createSelector(
  selectCheckoutFormData,
  (f: CheckoutFormData) => f.monoRepoArch
);

export const selectCheckoutWithMobileArch = createSelector(
  selectCheckoutFormData,
  (f: CheckoutFormData) => f.mobileArch
);

export const selectCheckoutWithDesktopArch = createSelector(
  selectCheckoutFormData,
  (f: CheckoutFormData) => f.desktopArch
);

export const selectCheckoutWithMobileOrDesktopArch = createSelector(
  selectCheckoutFormData,
  (f: CheckoutFormData) => f.mobileArch || f.desktopArch
);

export const selectTotalAmountDue = createSelector(
  selectCheckoutFormData,
  checkoutSubTotal
);

export const selectCheckoutFormsInValid = createSelector(
  selectCheckoutState,
  (state: CheckoutState) => {
    let isValid = state.checkoutFormStatus === 'VALID';

    // Monorepo
    if (isValid && state.checkoutFormData.monoRepoArch) {
      isValid = state.monoRepoFormStatus === 'VALID';
    }

    if (isValid && state.checkoutFormData.mobileArch) {
      isValid = state.mobileFormStatus === 'VALID';
    }

    if (isValid && state.checkoutFormData.desktopArch) {
      isValid = state.desktopFormStatus === 'VALID';
    }

    // Return if INVALID to disable buttons
    return !isValid;
  }
);

export const selectAllFormData = createSelector(
  selectCheckoutState,
  (state: CheckoutState): AllFormData => {
    return {
      checkoutFormData: state.checkoutFormData,
      monoRepoFormData: state.monoRepoFormData,
      mobileFormData: state.mobileFormData,
      desktopFormData: state.desktopFormData
    };
  }
);

export const selectAllFormDataWithPrices = createSelector(
  selectAllFormData,
  selectNgPatAllStripePrices,
  selectNgPatLoggedInUID,
  (
    formData: AllFormData,
    prices: NgPatStripeProductPrice[],
    uid: string | null
  ): AllFormDataWithPrice => {
    return {
      formData,
      prices,
      uid
    };
  }
);

export const selectAllFormDataLoaded = createSelector(
  selectCheckoutState,
  (state: CheckoutState): AllFormDataLoaded => {
    return {
      isLoaded: state.isLoaded,
      checkoutFormData: state.checkoutFormData,
      monoRepoFormData: state.monoRepoFormData,
      mobileFormData: state.mobileFormData,
      desktopFormData: state.desktopFormData
    };
  }
);

export const selectGetAllFormDataStatusLoaded = createSelector(
  selectCheckoutState,
  getAllFormDataStatusLoaded
);

export const selectGetAllFormDataStatus = createSelector(
  selectCheckoutState,
  getAllFormDataAndStatus
);
