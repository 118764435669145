import {InjectionToken} from '@angular/core';
import {AuthURLs} from '@gigasoftware/shared/domain';
import {ENV_SERVER_ENUM, FirebaseConfig} from '@ngpat/firebase';

export const ENVIRONMENT = new InjectionToken('ENVIRONMENT');

export const ENV_SERVER: InjectionToken<ENV_SERVER_ENUM> = new InjectionToken<ENV_SERVER_ENUM>(
  'ENV_SERVER'
);

export const EMULATOR_PORTS = {
  FIRESTORE: 8080,
  AUTH: 9099,
  STORAGE: 9199,
  FUNCTIONS: 5001
};

export interface AlgoliaSearchKeys {
  appId: string;
  apiKey: string;
  index: string;
}

export interface DeviceBuild {
  isMobileApp: boolean;
  marketingSite: string;
  marketingSiteSubscribe: string;
  authDisplay: string;
  authHD: string;
}

export interface IEnvironmentState {
  production: boolean;
  environment: string;
  isIosOrAndroid: boolean;
  firebase: FirebaseConfig;
  algolia: AlgoliaSearchKeys;
  stripePublishableKey: string;
  deviceBuild: DeviceBuild;
  authURLs: AuthURLs;
  remoteConfigPollMillis: number;
  defaultRemoteConfig: {
    trialDays: number;
    maxNumberQuizzesWhileInTrial: number;
  };
  remoteConfigParams: {
    settings: {
      /**
       * Defines the maximum age in milliseconds of an entry in the config cache before
       * it is considered stale. Defaults to 43200000 (Twelve hours).
       */
      minimumFetchIntervalMillis: number;
      /**
       * Defines the maximum amount of milliseconds to wait for a response when fetching
       * configuration from the Remote Config server. Defaults to 60000 (One minute).
       */
      fetchTimeoutMillis: number;
    };
  };
}

export interface LogoutRedirect {
  logoutRedirect: string[];
}

export const LOGOUT_REDIRECT = new InjectionToken('LOGOUT_REDIRECT');
