import { createAction, props } from '@ngrx/store';
import {
  CheckoutFormData,
  CheckoutState,
  DesktopFormData,
  MobileFormData,
  MonoRepoFormData
} from './checkout.model';
import { FormControlStatus } from '@angular/forms';

export const onInitCheckoutEffects = createAction(
  '[Checkout/API] On Init Checkout Effects'
);

export const checkoutError = createAction(
  '[Checkout/API] Error',
  props<{ message: string }>()
);

export const updateCheckoutState = createAction(
  '[Checkout/API] Update Checkout State',
  props<{ update: Partial<CheckoutState> }>()
);

export const updateCheckoutFromData = createAction(
  '[Checkout/API] Update Checkout Form Data',
  props<{ checkoutFormData: CheckoutFormData }>()
);

export const updateDesktopFormData = createAction(
  '[Checkout/API] Update Desktop Form Data',
  props<{ desktopFormData: DesktopFormData }>()
);

export const updateMobileFormData = createAction(
  '[Checkout/API] Update Mobile Form Data',
  props<{ mobileFormData: MobileFormData }>()
);

export const updateMonoRepoFormData = createAction(
  '[Checkout/API] Update MonoRepo Form Data',
  props<{ monoRepoFormData: MonoRepoFormData }>()
);

export const checkoutSuccessEmail = createAction(
  '[Checkout/API] Send Checkout Success Email'
);

//
// export const checkoutFormStatus = createAction(
//   '[Checkout/API] Checkout Form Status',
//   props<{ status: FormControlStatus }>()
// );
//
// export const monoRepoFormStatus = createAction(
//   '[Checkout/API] MonoRepo Form Status',
//   props<{ status: FormControlStatus }>()
// );
//
// export const mobileFormStatus = createAction(
//   '[Checkout/API] Mobile Form Status',
//   props<{ status: FormControlStatus }>()
// );
//
// export const desktopFormStatus = createAction(
//   '[Checkout/API] Desktop Form Status',
//   props<{ status: FormControlStatus }>()
// );

export const startPayment = createAction('[Checkout/API] Start Payment');

export const clearState = createAction('[Checkout/API] Clear State');
