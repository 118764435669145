import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Capacitor } from '@capacitor/core';
import { concatMap, map } from 'rxjs/operators';
import { Observable, EMPTY } from 'rxjs';
import * as DeviceActions from './device.actions';
import {Action, Store} from '@ngrx/store';
import {ngPatInitDevice, ngPatLoadDevices} from './device.actions';

@Injectable()
export class NgPatDeviceEffects implements OnInitEffects {
  loadDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeviceActions.ngPatLoadDevices),
      /** An EMPTY observable only emits completion. Replace with your own observable API request */
      concatMap(() => EMPTY as Observable<{ type: string }>)
    );
  });

  initDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ngPatInitDevice),
      map(() => {
        return DeviceActions.ngPatLoadDevices({
          device: {
            isLoaded: true,
            isNativePlatform: Capacitor.isNativePlatform(),
            web: Capacitor.getPlatform() === 'web',
            ios: Capacitor.getPlatform() === 'ios',
            android: Capacitor.getPlatform() === 'android'
          }
        });
      })
    );
  });

  constructor(
    private store: Store,
    private breakpointObserver: BreakpointObserver,
    private actions$: Actions) {

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Web,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
        Breakpoints.WebPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletLandscape,
        Breakpoints.WebLandscape
      ])
      // .pipe(map(breakpointState => breakpointState.matches))
      .subscribe((state: BreakpointState) => {

        const isPortrait = state.breakpoints[Breakpoints.HandsetPortrait] ||
          state.breakpoints[Breakpoints.TabletPortrait] ||
          state.breakpoints[Breakpoints.WebPortrait];

        const isLargeScreen = state.breakpoints[Breakpoints.Medium] ||
          state.breakpoints[Breakpoints.Large] ||
          state.breakpoints[Breakpoints.XLarge];

        this.store.dispatch(ngPatLoadDevices({
          device: {
            isPortrait,
            isLandscape: !isPortrait,
            isLargeScreen,

            xSmall: state.breakpoints[Breakpoints.XSmall],
            small: state.breakpoints[Breakpoints.Small],
            medium: state.breakpoints[Breakpoints.Medium],
            large: state.breakpoints[Breakpoints.Large],
            xLarge: state.breakpoints[Breakpoints.XLarge],
            handset: state.breakpoints[Breakpoints.Handset],
            tablet: state.breakpoints[Breakpoints.Tablet],

          }
        }));


        // this.store.dispatch(setLayout({
        //   isLargeScreen,
        //   isMobile: !isLargeScreen,
        //   isPortrait,
        //   isIOS
        // }));
        // this.store.dispatch(setIsDesktop({isLargeScreen}));
      });

  }

  ngrxOnInitEffects(): Action {
    return ngPatInitDevice();
  }
}
