import {
  IAPOffer,
  IAPProduct,
  IAPProductAndIRegisterProduct,
  IapticInAppPurchaseValidationResponse,
  VerifiedPurchaseWithProductId
} from './in-app-purchase.model';
import IRegisterProduct = CdvPurchase.IRegisterProduct;

/**
 * Convert a CdvPurchase.Product to an IAPProduct
 * @param product
 */
export function transformCdvPurchaseProduct(product: CdvPurchase.Product): IAPProduct {
  return {
    className: product.className,
    platform: product.platform,
    type: product.type,
    id: product.id,
    offers: product.offers.map(stringifyCdvPurchaseOffer),
    title: product.title,
    description: product.description,
    group: product.group || product.title,
    canPurchase: product.canPurchase,
    owned: product.owned,
    features: []
  };
}

/**
 * @param response
 * @param registeredProducts
 */

export function getActiveInAppPurchase(
  response: IapticInAppPurchaseValidationResponse,
  registeredProducts: IAPProductAndIRegisterProduct[]
): IAPProductAndIRegisterProduct[] {
  if (response && response && response.purchases) {
    // Add product id to the purchase
    return <IAPProductAndIRegisterProduct[]>Object.keys(response.purchases)
      .map(verifiedProductId => {
        const [deviceId, productId] = verifiedProductId.split(':');

        return <VerifiedPurchaseWithProductId>{
          productId,
          deviceId,
          ...response.purchases[productId]
        };
      })

      // Only return active purchases
      .filter((p: VerifiedPurchaseWithProductId) => !p.isExpired)
      .map((p: VerifiedPurchaseWithProductId) => {
        const registeredProduct = registeredProducts.find(rp => rp.id === p.productId);
        if (registeredProduct) {
          return {
            ...registeredProduct,
            owned: true
          };
        }
        return null;
      })
      .filter(p => p !== null);
  }

  return [];
}

export function stringifyCdvPurchaseOffer(offer: CdvPurchase.Offer): IAPOffer {
  return {
    id: offer.id,
    productId: offer.productId,
    productGroup: offer.productGroup,
    platform: offer.platform,
    pricingPhases: offer.pricingPhases,
    canPurchase: offer.canPurchase
  };
}
