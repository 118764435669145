export interface NOTIFICATION_ID {
  APP_UPDATE: string;
  [key: string]: string;
}

export const DEFAULT_NOTIFICATION: NOTIFICATION_ID = {
  APP_UPDATE: 'APP_UPDATE_NOTIFICATION'
};

export interface UiNotification {
  id: string;

  title: string;
  message: string;
}
