import {InjectionToken} from '@angular/core';
import {gt} from 'semver';

export interface BuildVersionUi {
  angularVersion: string;
  app: string;
  buildNumber: number;
  changeLog: string;
  month: string;
  releaseType: string;
  year: number;
}

export const APP_VERSION = new InjectionToken('APP_VERSION');

export interface InstalledAppVersion {
  version: BuildVersionUi;
  firestorePath: string;
}

export function getVersionMonth(m: string): number {
  return parseInt(m, 10);
}

export function upgradedIsUploaded(
  installed: BuildVersionUi,
  uploaded: BuildVersionUi
): boolean {
  const uploadedVersion = `${uploaded.year}.${uploaded.month}.${uploaded.buildNumber}`;
  const installedVersion = `${installed.year}.${installed.month}.${installed.buildNumber}`;

  return gt(uploadedVersion, installedVersion);
}

export function getUpgradeVersionString(uploaded: BuildVersionUi): string {
  return `${uploaded.year}.${uploaded.month}.${uploaded.buildNumber}`;
}
