import {FormControlStatus} from '@angular/forms';
import {NgPatStripeProductPrice} from '@ngpat/store';

export interface FeatureItem {
  label: string;
  isLink: boolean;
  href: string;
  highlight1: boolean;
  highlight2: boolean;
}

export interface Product {
  title: string;
  description: string;
  requirements: string;
  price: number;
  features: FeatureItem[];
}

export const monoRepoArch: Product = {
  title: 'Create Nx / Ng Monorepo Architecture',
  description: 'Create an Angular, Nx, and Firebase starter repo with configured frameworks.',
  requirements: '',
  price: 500,
  features: [
    {
      label: 'Create an Nx / Angular monorepo in GitHub.',
      isLink: false,
      href: 'https://nx.dev/',
      highlight1: false,
      highlight2: false
    },
    {
      label: 'Create and deploy to a Firebase project.',
      isLink: true,
      href: 'https://firebase.google.com/',
      highlight1: false,
      highlight2: false
    },
    {
      label: 'Create a starter application with repo-arch libraries.',
      isLink: true,
      href: 'https://www.angulararchitects.io/en/aktuelles/tactical-domain-driven-design-with-monorepos/',
      highlight1: false,
      highlight2: false
    },
    {
      label: 'Create a design library.',
      isLink: false,
      href: '',
      highlight1: false,
      highlight2: false
    },
    {
      label: 'Configure Storybook.',
      isLink: true,
      href: 'https://storybook.js.org/tutorials/intro-to-storybook/angular/en/get-started/',
      highlight1: false,
      highlight2: false
    },
    {
      label: 'Configure Compodoc.',
      isLink: true,
      href: 'https://compodoc.app/',
      highlight1: false,
      highlight2: false
    },
    {
      label: 'Configure NgRX with a mock starter store.',
      isLink: true,
      href: 'https://ngrx.io/',
      highlight1: false,
      highlight2: false
    },
    {
      label: 'Configure Angular Material.',
      isLink: true,
      href: 'https://material.angular.io/',
      highlight1: false,
      highlight2: false
    },
    {
      label: 'Configure TailwindCSS.',
      isLink: true,
      href: 'https://tailwindcss.com/',
      highlight1: false,
      highlight2: false
    }
  ]
};

export const mobileArch: Product = {
  title: 'Add Mobile Architecture ( CapacitorJS )',
  description: 'Add CapacitorJS to your monorepo project to develop mobile and web apps.',
  requirements: 'Requires monorepo architecture created by GigaSoft',
  price: 100,
  features: [
    {
      label: 'Requires Nx / Ng Monorepo Architecture created by GigaSoftware.',
      isLink: false,
      href: '',
      highlight1: true,
      highlight2: false
    },
    {
      label: 'Configure Capacitor for iOS and Android development.',
      isLink: true,
      href: 'https://capacitorjs.com/',
      highlight1: true,
      highlight2: false
    }
  ]
};

export const desktopArch: Product = {
  title: 'Add Desktop Architecture ( ElectronJS )',
  description: 'Add ElectronJS to your monorepo project to develop desktop and web apps.',
  requirements: 'Requires monorepo architecture created by GigaSoft',
  price: 100,
  features: [
    {
      label: 'Requires Nx / Ng Monorepo Architecture created by GigaSoftware.',
      isLink: false,
      href: '',
      highlight1: true,
      highlight2: false
    },
    {
      label: 'Configure Electron for mobile development.',
      isLink: true,
      href: 'https://www.electronjs.org/',
      highlight1: true,
      highlight2: false
    }
  ]
};
export const products: Product[] = [monoRepoArch, mobileArch, desktopArch];

export interface CheckoutFormData {
  contactEmail: string;
  monoRepoArch: boolean;
  mobileArch: boolean;
  desktopArch: boolean;
}

export interface MonoRepoFormData {
  repositoryName: string;
  domainName: string;
  initialAppName: string;
  // sameDomainForMobileAndDesktopApps: boolean;
}

export interface MobileFormData {
  mobileAppName: string;
  domainName: string;
}

export interface DesktopFormData {
  desktopAppName: string;
  domainName: string;
}

export function checkoutSubTotal(f: CheckoutFormData): number {
  let total = 0;

  if (f.monoRepoArch) {
    total += monoRepoArch.price;
  }

  if (f.mobileArch) {
    total += mobileArch.price;
  }

  if (f.desktopArch) {
    total += desktopArch.price;
  }

  return total;
}

export type SameDomain = Pick<MonoRepoFormData, 'domainName'>;

export interface AllFormData {
  checkoutFormData: CheckoutFormData;
  monoRepoFormData: MonoRepoFormData;
  mobileFormData: MobileFormData;
  desktopFormData: DesktopFormData;
}

export interface AllFormDataLoaded extends AllFormData {
  isLoaded: boolean;
}

export interface ArchDomainLibs {
  libsDomainDirectoryName: string;
  apiDirectoryName: string;
  domainDirectoryName: string;
  featureDirectoryName: string;
  uiDirectoryName: string;
  utilDirectoryName: string;
}

export interface ArchDomainApps {
  appsDomainDirectoryName: string;
  appDirectoryName: string;
  appE2EDirectoryName: string;

  mobileDirectoryName: string;
  desktopDirectoryName: string;

  storyBookDirectoryName: string;
  storyBookAppDirectoryName: string;
  storyBookE2EDirectoryName: string;

  firebaseDirectoryName: string;
}

export interface CheckoutState {
  // additional entities state properties
  isLoaded: boolean;
  isLoading: boolean;
  error: string | null;

  checkoutFormStatus: FormControlStatus;
  monoRepoFormStatus: FormControlStatus;
  mobileFormStatus: FormControlStatus;
  desktopFormStatus: FormControlStatus;

  checkoutFormData: CheckoutFormData;
  monoRepoFormData: MonoRepoFormData;
  mobileFormData: MobileFormData;
  desktopFormData: DesktopFormData;

  monoRepoArchProduct: Product;
  mobileArchProduct: Product;
  desktopArchProduct: Product;

  apps: ArchDomainApps;
  libs: ArchDomainLibs;
}

export type AllFormDataStatus = Pick<
  CheckoutState,
  | 'checkoutFormStatus'
  | 'monoRepoFormStatus'
  | 'mobileFormStatus'
  | 'desktopFormStatus'
  | 'checkoutFormData'
  | 'monoRepoFormData'
  | 'mobileFormData'
  | 'desktopFormData'
  | 'apps'
  | 'libs'
>;

export interface AllFormDataStatusLoaded extends AllFormDataStatus {
  isLoaded: boolean;
}

export interface GigaPriceMetaData {
  firebasePrice: string;
  firebaseProduct: string;
}

export interface AllFormDataWithPrice {
  formData: AllFormData;
  prices: NgPatStripeProductPrice[];
  uid: string | null;
}

export interface StripeProduct {
  MONOREPO: string;
  DESKTOP: string;
  MOBILE: string;
}

export const stripeProducts: StripeProduct = {
  MONOREPO: 'monorepo',
  DESKTOP: 'desktop',
  MOBILE: 'mobile'
};
