import {oneDay} from '@ngpat/date';
import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {NgPatStripePromoCode, selectNgPatStripePromoCodeEntities} from '../+promo-codes';
import {
  NgPatStripeTrialParams,
  selectNgPatHasActiveStripeSubscription,
  selectStripeTrialDays
} from '../+subscriptions';
import {WebSubscriptionProducts} from '../../+in_app_purchase/index';
import {NgPatAccountState} from '../../../+account/account.model';
import {selectNgPatAccountState} from '../../../+account/account.selectors';
import * as ProductReducer from './product.reducer';
import {ProductState} from './product.reducer';

export const selectNgPatStripeProductState = createFeatureSelector<ProductReducer.ProductState>(
  ProductReducer.productFeatureKey
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  ProductReducer.productAdapter.getSelectors();

export const selectNgPatStripeAllProducts = createSelector(
  selectNgPatStripeProductState,
  (state: ProductState) => selectAll(state)
);
export const selectNgPatStripeProductEntities = createSelector(
  selectNgPatStripeProductState,
  (state: ProductState) => selectEntities(state)
);
export const selectNgPatStripeProductIds = createSelector(
  selectNgPatStripeProductState,
  (state: ProductState) => selectIds(state)
);
export const selectNgPatStripeProductTotal = createSelector(
  selectNgPatStripeProductState,
  (state: ProductState) => selectTotal(state)
);

export const selectHardCodedSubscriptionWebProducts = createSelector(
  selectNgPatStripeProductState,
  (state: ProductState): WebSubscriptionProducts => {
    return state.hardCodedSubscriptionProducts;
  }
);

/**
 * selectedProductID
 */
// export const selectNgPatCurrentStripeProductID = createSelector(
//   selectNgPatStripeProductState,
//   (state: ProductReducer.ProductState) => state.selectedProductID
// );
//
// export const selectNgPatGetStripeProductByID = (id: string) =>
//   createSelector(selectNgPatStripeProductEntities, (entities: Dictionary<NgPatStripeProduct>) => {
//     return entities[id];
//   });

// export const selectNgPatStripeProductsWiPrices = createSelector(
//   selectNgPatStripeAllProducts,
//   selectNgPatAllStripePrices,
//   (
//     products: NgPatStripeProduct[],
//     prices: NgPatStripeProductPrice[]
//   ): NgPatStripeProductWithPrices[] => {
//     const _productWithPrices: NgPatStripeProductWithPrices[] = products.map(
//       (product: NgPatStripeProduct) => {
//         return {
//           product,
//           prices: prices.filter((price: NgPatStripeProductPrice) => price.product === product.id)
//         };
//       }
//     );
//
//     const _productWithPricesSorted = [];
//
//     for (let i = 0; i < _productWithPrices.length; i++) {
//       if (_productWithPrices[i].product.name.includes('Student')) {
//         _productWithPricesSorted[0] = _productWithPrices[i];
//       } else if (_productWithPrices[i].product.name.includes('Mentor')) {
//         _productWithPricesSorted[1] = _productWithPrices[i];
//       } else {
//         _productWithPricesSorted[2] = _productWithPrices[i];
//       }
//     }
//
//     return _productWithPricesSorted;
//   }
// );

/**
 * TODO remove
 * @deprecated = Remove
 * A promo code is provided by they payment platform only
 */
export const selectNgPatStripeTrialParams = createSelector(
  selectNgPatAccountState,
  selectNgPatHasActiveStripeSubscription,
  selectNgPatStripePromoCodeEntities,
  selectStripeTrialDays,
  (
    a: NgPatAccountState,
    hasActiveSubscription: boolean,
    promoCodeEntities: Dictionary<NgPatStripePromoCode>,
    trialDays: number
  ): NgPatStripeTrialParams => {
    if (a && a.createdAt !== null && a.createdAt.seconds !== null) {
      const now: number = Date.now().valueOf();
      const start = new Date(a.createdAt.seconds * 1000);
      const remainingDays = trialDays - (now - start.valueOf()) / oneDay;

      return {
        days: trialDays,
        remaining: remainingDays,
        hasActiveSubscription,
        hasPromoCode: false,
        isInTrial: remainingDays > 0 && !hasActiveSubscription
      };
    }

    return {
      days: 0,
      remaining: 0,
      hasPromoCode: false,
      isInTrial: false,
      hasActiveSubscription
    };
  }
);

// export const selectNgPatStripeIsInTrial$ = pipe(
//   select(selectNgPatStripeTrialParams),
//   map(({isInTrial}: NgPatStripeTrialParams) => isInTrial),
//   distinctUntilChanged()
// );
