export enum DEVICE_TYPE {
  ANDROID = 'android',
  IOS = 'ios',
  WEB = 'web',
  NATIVE = 'native'
}

export interface IAPProductOptions {
  id: string;
  alias?: string;
  type: string;
}

export interface IRefeshResult {
  cancelled(fn: () => void): void;

  failed(fn: () => void): void;

  completed(fn: () => void): void;

  finished(fn: () => void): void;
}

export type PlayStoreReceipt = {
  id: string;
  purchaseState: number;
  purchaseToken: string;
  receipt: string;
  signature: string;
  type: 'android-playstore';
};

export type AppStoreReceipt = {
  id: string;
  appStoreReceipt: string;
  original_transaction_id: string;
  type: 'ios-appstore';
};

/**
 * @hidden
 */
export class IAPError {
  code!: number;
  message!: string;
}

export interface InAppPurchaseConfig {
  apiKey: string;
  appName: string;
  baseUrl?: string;
  win: Window;
}
