import {Inject, Injectable} from '@angular/core';
import { DEFAULT_NOTIFICATION } from './notification.model';
import { onNotificationsEffectInit, upsertNotification } from './notification.actions';
import {
  APP_VERSION,
  BuildVersionUi,
  getUpgradeVersionString,
  InstalledAppVersion,
  upgradedIsUploaded
} from '@gigasoftware/shared/models';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {DocumentData, DocumentSnapshot, onSnapshot} from 'firebase/firestore';
import {tap} from 'rxjs/operators';
import {PartialNotificationState} from './notification.reducer';

@Injectable({
  providedIn: 'root'
})
export class NotificationEffects {
  onNotificationsEffectInit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onNotificationsEffectInit),
        tap(() => {
          this.watchForAppVersionChanges();
        })
      ),
    {dispatch: false}
  );

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private actions$: Actions,
    @Inject(APP_VERSION) private _appVersion: InstalledAppVersion,
    private store: Store<PartialNotificationState>
  ) {}

  ngrxOnInitEffects(): Action {
    return onNotificationsEffectInit();
  }

  private watchForAppVersionChanges(): void {
    // this.store.dispatch(
    //   upsertNotification({
    //     notification: {
    //       id: DEFAULT_NOTIFICATION.APP_UPDATE,
    //       title: 'Upgrade Available',
    //       message: `vTest`
    //     }
    //   })
    // );

    onSnapshot(
      this.customFirestoreService.docRef(this._appVersion.firestorePath),
      (_doc: DocumentSnapshot<DocumentData>) => {
        if (_doc.exists()) {
          const uploadedBuildVersion: BuildVersionUi = <BuildVersionUi>(
            _doc.data()
          );

          if (
            upgradedIsUploaded(this._appVersion.version, uploadedBuildVersion)
          ) {
            this.store.dispatch(
              upsertNotification({
                notification: {
                  id: DEFAULT_NOTIFICATION.APP_UPDATE,
                  title: 'Upgrade Available',
                  message: `v${getUpgradeVersionString(uploadedBuildVersion)}`
                }
              })
            );
          }
        }
      }
    );
  }
}
