import { Subject, interval, takeUntil } from 'rxjs';

export interface MaxIntervalCount {
  interval: number;
  totalTime: number;
  maxCount: number;
}

/**
 * Get the max count of intervals in a given time
 * @param interval - interval time in milliseconds
 * @param totalTime - total time in milliseconds
 * @return integer - max count of intervals
 */
export function getMaxCountInInterval(interval: number, totalTime: number): MaxIntervalCount {

  // Make sure interval is positive and an integer
  const _interval = Math.abs(Math.floor(interval));

  // Make sure totalTime is positive and an integer
  const _totalTime = Math.abs(Math.floor(totalTime));

  // Check if interval is 0
  if (_interval === 0) {
    return {
      interval,
      totalTime,
      maxCount: 0
    }
  } else if (_totalTime === 0) {
    return {
      interval,
      totalTime,
      maxCount: 0
    }
  } else if (_interval > _totalTime) {
    return {
      interval,
      totalTime,
      maxCount: 1
    }
  } else if (_interval === _totalTime) {
    return {
      interval,
      totalTime,
      maxCount: 1
    }
  } else if (_interval < _totalTime) {

    return {
      interval,
      totalTime,
      maxCount: Math.floor(_totalTime / _interval)
    }

  } else {
    return {
      interval,
      totalTime,
      maxCount: 0
    }
  }

}


export class MaxCountIterator {

  private _destroy$: Subject<boolean> = new Subject()
  private _count = 0;
  private _params: MaxIntervalCount = {
    interval: 0,
    totalTime: 0,
    maxCount: 0
  };
  private _isRunning = false;

  currentCount$ = new Subject<number>();

  get isMaxCountReached() {
    return this._count > this._params.maxCount;
  }

  constructor(private interval: number, private totalTime: number | null, maxCount: number | null = null) {
    if (maxCount) {
      this._params = {
        interval,
        totalTime: totalTime || 0,
        maxCount
      }
    }
    if (totalTime) {
      this._params = getMaxCountInInterval(interval, totalTime);
    }

    if (!totalTime && !maxCount) {
      throw new Error('MaxCountIterator Error: You must provide a totalTime or maxCount');
    }
  }

  start() {
    if (!this._isRunning) {
      this._isRunning = true;

      interval(this._params.interval)
        .pipe(
          takeUntil(this._destroy$)
        ).subscribe(() => {
        if (this._count > this._params.maxCount) {
          this.stop();
        } else {
          this._count++;
          this.currentCount$.next(this._count);
        }
      })
    }
  }

  stop() {
    this._isRunning = false;
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
