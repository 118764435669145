import {Type} from '@angular/core';
import {NgPatAccountEffects} from './+account/account.effects';
import {ngPatAccountFeatureKey, ngPatInitialAccountState} from './+account/account.model';
import * as fromAccountState from './+account/account.reducer';
import {NgPatDeviceEffects} from './+device/device.effects';
import {
  ngPatDeviceFeatureKey,
  ngPatDeviceReducer,
  ngPatInitialDeviceState
} from './+device/device.reducer';
import {NgPatInAppPurchaseEffects} from './+pricing/+in_app_purchase/in-app-purchase.effects';
import * as fromAppStore from './+pricing/+in_app_purchase/in-app-purchase.reducer';
import {NgPatInAppPurchaseService} from './+pricing/+in_app_purchase/ng-pat-in-app-purchase.service';
import {ngPatIInitialLocalStorageState, ngPatLocalStoragesFeatureKey} from './+local-storage';
import {NgPatLocalStorageEffects} from './+local-storage/local-storage.effects';
import * as fromLocalStorageState from './+local-storage/local-storage.reducer';
import {NotificationEffects} from './+notification/notification.effects';
import * as fromNotification from './+notification/notification.reducer';
import {NgPatRemoteConfigEffects} from './+remote-config/remote-config.effects';
import * as fromRemoteConfigState from './+remote-config/remote-config.reducer';
import {
  ngPatInitialRemoteConfigState,
  ngPatRemoteConfigFeatureKey
} from './+remote-config/remote-config.reducer';
import {NG_PAT_WEB_EFFECTS, NG_PAT_WEB_SERVICES} from './+pricing/+stripe/index';
import {NgPatWebsocketRegistryEffects} from './+websocket-registry/websocket-registry.effects';
import {
  ngPatInitialWebsocketRegistryState,
  ngPatWebsocketRegistryFeatureKey
} from './+websocket-registry/websocket-registry.models';
import * as fromFirebaseConnectionsState from './+websocket-registry/websocket-registry.reducer';

export const NG_PAT_FIREBASE_ROOT_REDUCERS = {
  [ngPatAccountFeatureKey]: fromAccountState.ngPatAccountReducer,
  [ngPatRemoteConfigFeatureKey]: fromRemoteConfigState.ngPatRemoteConfigReducer,
  [ngPatWebsocketRegistryFeatureKey]: fromFirebaseConnectionsState.ngPatWebSocketReducer,
  [ngPatLocalStoragesFeatureKey]: fromLocalStorageState.localStorageReducer,
  // [ngPatDialogsFeatureKey]: ngPatDialogueQueueReducer,
  [ngPatDeviceFeatureKey]: ngPatDeviceReducer,
  [fromAppStore.appstoreInAppPurchaseFeatureKey]: fromAppStore.iapReducer,
  [fromNotification.notificationFeatureKey]: fromNotification.notificationReducer
};

export const NG_PAT_FIREBASE_ROOT_STATE_INITIALIZERS = {
  [ngPatAccountFeatureKey]: ngPatInitialAccountState,
  [ngPatRemoteConfigFeatureKey]: ngPatInitialRemoteConfigState,
  [ngPatWebsocketRegistryFeatureKey]: ngPatInitialWebsocketRegistryState,
  [ngPatLocalStoragesFeatureKey]: ngPatIInitialLocalStorageState,
  // [ngPatDialogsFeatureKey]: ngPatInitialDialogState,
  [ngPatDeviceFeatureKey]: ngPatInitialDeviceState,
  [fromAppStore.appstoreInAppPurchaseFeatureKey]: fromAppStore.initialAppstoreInAppPurchaseState,
  [fromNotification.notificationFeatureKey]: fromNotification.initialNotificationState
};

export const NG_PAT_FIREBASE_ROOT_EFFECTS: Type<unknown>[] = [
  NgPatAccountEffects,
  NgPatRemoteConfigEffects,
  NgPatLocalStorageEffects,
  // NgPatDialogQueue,
  NgPatDeviceEffects,
  NotificationEffects,
  NgPatInAppPurchaseEffects,
  NgPatWebsocketRegistryEffects,
  ...NG_PAT_WEB_EFFECTS
];

export const NG_PAT_FIREBASE_ROOT_PROVIDERS = [NgPatInAppPurchaseService, ...NG_PAT_WEB_SERVICES];
