import {EntityMap, EntityMapOne, Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {IAPMessage, IAPProduct, IAPProductAndIRegisterProduct} from './in-app-purchase.model';

export const onAppStoreInitEffect = createAction('[IAPProduct/API] App Store Init');

export const addAppstoreInAppPurchase = createAction(
  '[IAPProduct/API] Add IAPProduct',
  props<{appstoreInAppPurchase: IAPProduct}>()
);

export const setAppstoreInAppPurchase = createAction(
  '[IAPProduct/API] Set IAPProduct',
  props<{appstoreInAppPurchase: IAPProduct}>()
);

export const upsertAppstoreInAppPurchase = createAction(
  '[IAPProduct/API] Upsert IAPProduct',
  props<{appstoreInAppPurchase: IAPProduct}>()
);

export const addAppstoreInAppPurchases = createAction(
  '[IAPProduct/API] Add AppstoreInAppPurchases',
  props<{appstoreInAppPurchases: IAPProduct[]}>()
);

export const upsertAppstoreInAppPurchases = createAction(
  '[IAPProduct/API] Upsert AppstoreInAppPurchases',
  props<{appstoreInAppPurchases: IAPProduct[]}>()
);

export const updateAppstoreInAppPurchase = createAction(
  '[IAPProduct/API] Update IAPProduct',
  props<{appstoreInAppPurchase: Update<IAPProduct>}>()
);

export const updateAppstoreInAppPurchases = createAction(
  '[IAPProduct/API] Update AppstoreInAppPurchases',
  props<{appstoreInAppPurchases: Update<IAPProduct>[]}>()
);

export const mapAppstoreInAppPurchase = createAction(
  '[IAPProduct/API] Map IAPProduct',
  props<{entityMap: EntityMapOne<IAPProduct>}>()
);

export const mapAppstoreInAppPurchases = createAction(
  '[IAPProduct/API] Map AppstoreInAppPurchases',
  props<{entityMap: EntityMap<IAPProduct>}>()
);

export const deleteAppstoreInAppPurchase = createAction(
  '[IAPProduct/API] Delete IAPProduct',
  props<{id: string}>()
);

export const deleteAppstoreInAppPurchaseFromfirestore = createAction(
  '[eAppstoreInAppPurchase/API] Delete eAppstoreInAppPurchase From Firestore',
  props<{id: string}>()
);

export const deleteAppstoreInAppPurchases = createAction(
  '[IAPProduct/API] Delete AppstoreInAppPurchases',
  props<{ids: string[]}>()
);

export const loadAppstoreInAppPurchases = createAction(
  '[IAPProduct/API] Load AppstoreInAppPurchases',
  props<{appstoreInAppPurchases: IAPProduct[]}>()
);

export const setAppstoreInAppPurchases = createAction(
  '[IAPProduct/API] Set AppstoreInAppPurchases',
  props<{appstoreInAppPurchases: IAPProduct[]}>()
);

export const clearAppstoreInAppPurchases = createAction(
  '[IAPProduct/API] Clear AppstoreInAppPurchases'
);

export const appstoreInAppPurchaseID = createAction(
  '[IAPProduct/API] Select IAPProduct',
  props<{id: string}>()
);

export const updateFirestorePartialAppstoreInAppPurchase = createAction(
  '[IAPProduct/API] Save Partial IAPProduct',
  props<{
    changes: Partial<IAPProduct>;
    appstoreInAppPurchase: IAPProduct;
  }>()
);

export const upsertAppStoreProducts = createAction(
  '[IAPProduct/API] Add App Store Product',
  props<{products: IAPProduct[]}>()
);

export const appStoreCheckoutInProgress = createAction(
  '[IAPProduct/API] Checkout In Progress',
  props<{checkoutInProgress: boolean}>()
);

export const appStorePurchaseSuccess = createAction(
  '[IAPProduct/API] Product Owned',
  props<{productId: string}>()
);

export const appStorePurchaseRestore = createAction('[IAPProduct/API] Restore Purchases');

export const appStoreMessage = createAction('[IAPProduct/API] Message', props<IAPMessage>());

export const loadHardCodedSubscriptionToIAPProducts = createAction(
  '[IAPProduct/API] Load Subscription Products',
  props<{hardCodedSubscriptionProducts: IAPProductAndIRegisterProduct[]}>()
);
